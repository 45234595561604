/* ----- Animate on view ----- */

/* Common effects */
[data-view] {
    transition-duration: var(--view-speed);
    transition-delay: var(--view-delay);
    transition-timing-function: var(--view-easing);

    &.animated {
        opacity: 1;
        transform: translateZ(0);
    }
}

[data-view="fade"],
[data-view^="fade"],
[data-view="zoom"],
[data-view^="zoom"] {
    opacity: 0;
    transition-property: opacity, transform;
}

/* Slide effect */
[data-view="slide"],
[data-view^="slide"] {
    transition-property: transform;

    &.animated {
        transform: translateZ(0);
    }
}

/* Fade and slide effects */
[data-view="slide-tl"],
[data-view="fade-tl"] {
    transform: translateY(100px) translateX(100px);
}

[data-view="slide-tr"],
[data-view="fade-tr"] {
    transform: translateY(100px) translateX(-100px);
}

[data-view="slide-bl"],
[data-view="fade-bl"] {
    transform: translateY(-100px) translateX(100px);
}

[data-view="slide-br"],
[data-view="fade-br"] {
    transform: translateY(-100px) translateX(-100px);
}

[data-view="fade-left"],
[data-view="slide-left"] {
    transform: translateX(100px);
}

[data-view="fade-right"],
[data-view="slide-right"] {
    transform: translateX(-100px);
}

[data-view="fade-up"],
[data-view="slide-up"] {
    transform: translateY(100px);
}

[data-view="fade-down"],
[data-view="slide-down"] {
    transform: translateY(-100px);
}

/* Zoom effect */
[data-view^="zoom"],
[data-view^="zoom"] {
    &.animated {
        opacity: 1;
        transform: translateZ(0) scale(1);
    }
}

[data-view="zoom-in"] {
    transform: scale(0.1);
}

[data-view="zoom-in-left"] {
    transform: translateX(100px) scale(0.1);
}

[data-view="zoom-in-tl"] {
    transform: translateY(100px) translateX(100px) scale(0.1);
}

[data-view="zoom-in-tr"] {
    transform: translateY(100px) translateX(-100px) scale(0.1);
}

[data-view="zoom-in-br"] {
    transform: translateY(-100px) translateX(-100px) scale(0.1);
}

[data-view="zoom-in-bl"] {
    transform: translateY(-100px) translateX(100px) scale(0.1);
}

[data-view="zoom-in-right"] {
    transform: translateX(-100px) scale(0.1);
}

[data-view="zoom-in-up"] {
    transform: translateY(100px) scale(0.1);
}

[data-view="zoom-in-down"] {
    transform: translateY(-100px) scale(0.1);
}

/* Rotate effect */
[data-view^="rotate"],
[data-view^="rotate"] {
    opacity: 0;
    transform: rotate(180deg);
}

[data-view="rotate-left"] {
    transform: rotate(180deg) translateX(-100px);
}

[data-view="rotate-right"] {
    transform: rotate(180deg) translateX(100px);
}

[data-view="rotate-up"] {
    transform: rotate(180deg) translateY(-100px);
}

[data-view="rotate-down"] {
    transform: rotate(180deg) translateY(100px);
}
