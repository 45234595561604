.testimonial-card {
    background: var(--grey);
    border-radius: 5px;
    overflow: hidden;
    height: auto;

    /*&__img {
		max-width: 182px;
		flex-shrink: 0;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}*/

    &__info {
        padding: ac(36px, 20px, 1024) 11px ac(32px, 20px, 1024)
            ac(36px, 20px, 1024);
        width: 100%;

        display: flex;
        flex-direction: column;
        height: 100%;

        .content-element {
            margin-bottom: ac(32px, 20px, 1024);
            max-height: 124px;
            width: 100%;

            p {
                font: 400 var(--font-sec) 17px /177% !important;
            }
        }

        h3 {
            font-weight: 500;
            margin-bottom: 5px;
            font-size: ac(20px, 18px);
            margin-top: auto;
        }

        h4 {
            font-size: 16px;
            font-weight: 400;
        }
    }

    @mixin tab {
        &__info {
            .content-element {
                max-height: 215px;

                p {
                    font: 400 var(--font-sec) 15px /177% !important;
                }
            }
        }
    }

    @mixin media 651 {
        max-width: 300px;
    }

    @mixin media 460 {
        flex-direction: column;

        &__img {
            max-width: 100%;
            @mixin aspect-ratio 1, 1;

            img {
                object-position: top;
            }
        }
    }

    @mixin media 415 {
        max-width: 100%;
    }
}
