html {
    font-size: 16px;
    scroll-behavior: smooth;
}

.fp-watermark {
    display: none;
}

body {
    position: relative;
    min-width: 320px;
    margin: auto;
    background: var(--white);
    color: var(--black);
    font-family: var(--font-main);

    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 20px;
        background: var(--black);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--green);
    }
}

.swiper-wrapper {
    left: 0 !important;
}

.swiper-slide {
    user-select: none;
}

#site {
    position: relative;
    flex-direction: column;
}

.main {
    flex-grow: 1;
}

.cont {
    margin: 0 auto;
    max-width: 1221px;
    width: perc(1221);

    &.md {
        max-width: 1016px;
    }

    @mixin tab-md {
        width: 89%;
    }
}

section {
    position: relative;
    overflow-x: hidden;
}

.subtitle {
    font-weight: 700;
    font-size: ac(15px, 14px);
    margin-bottom: ac(15px, 6px);
    text-transform: capitalize;
    min-height: 23px;
}

.lazyload {
    opacity: 0;
    transition: opacity 0.2s ease;
}

.lazyloaded {
    opacity: 1;
}

[data-simplebar] {
    padding-right: 20px;
    margin-right: -20px;
}

.hover-link {
    color: var(--white);
    position: relative;
    @mixin transition-all;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 2px;
        background: var(--white);
        transition: 0.3s ease-in-out;
    }

    &:hover {
        color: var(--green);

        &::before {
            width: 100%;
            left: 0;
            background: var(--green);
        }
    }
}

.contact-link {
    font-size: 14px;
    font-weight: 400;
    width: fit-content;
}

.social-link {
    font-size: 34px;
    color: var(--green);
    line-height: 1;
    transition: 0.5s ease;

    &:hover {
        transform: translateY(-10px);
        color: var(--white);
    }
}

.share-block {
    display: flex;
    align-items: center;

    p {
        font-size: 18px;
        margin-right: 27px;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 36px;
        height: 36px;
        color: var(--black);
        font-size: 18px;
        transition: 0.3s ease;
        border-radius: 2px;
        position: relative;
        border: 1px solid var(--green);

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            transition: 0.3s ease;
            background: var(--green);
            pointer-events: none;
        }

        i {
            position: relative;
            z-index: 5;
            transition: transform 0.5s ease;
        }

        &:not(:last-child) {
            margin-right: 10px;
        }

        &:hover {
            &:before {
                height: 0;
            }

            i {
                color: var(--green);
                transform: rotateY(360deg);
            }
        }
    }
}

.sections-wrap {
    position: relative;
    overflow: hidden;
    background: url("../images/bridge-light.svg") no-repeat, var(--white);

    background-size: 180vw;
    background-position: 41% 0;

    &--solutions {
        background: none;

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            top: 0;
            height: 1242px;
            background: url("../images/bridge-light-mirror.svg") no-repeat;

            background-position: 67% 0%;
            background-attachment: fixed;

            background-size: 2148px;
        }
    }

    &--hiring {
        background-image: url("../images/hiring-bg.svg");
        background-size: cover;
        background-position: initial;
    }

    &--main {
        background-image: url("../images/home-center-bg.svg");
        background-position: center;
        background-size: cover;
    }

    &.not-bg {
        background: none;
        overflow: hidden;
        position: relative;
        z-index: 1;

        .sections-bg {
            @mixin aspect-ratio 2383, 4010;
            position: absolute;
            z-index: -1;
            width: 165.49%;
            top: 0;
            left: -15.21%;
            opacity: .08;
        }

    }
}

#bold-credits {
    width: 150px;
    transition: ease-in-out 0.25s;

    &::before,
    &::after {
        display: none;
    }

    &:hover {
        transform: scale(1.1);
    }
}

#site.cookie-terms-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    .main {
        flex-grow: 1;
        padding: 0;
    }
}

.cookie-terms-section {
    background: var(--black);
    color: var(--white);
    padding: ac(200px, 140px) 0 ac(150px, 50px);
    background: url("../images/bridge-svg.svg") no-repeat center / cover,
        var(--black);

    .cont {
        position: relative;
        z-index: 5;
    }

    .svg-bg {
        mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1), transparent 80%);
    }
}

.single-page-404 {
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    background: url("../images/bridge-svg.svg") no-repeat center / cover,
        var(--black);

    h1,
    p,
    a {
        position: relative;
        z-index: 10;
    }

    h1,
    p {
        margin-bottom: 30px;
        padding: 0;
    }
}

/* Pagination */
.pagination {
    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        margin: 0 auto;
        position: relative;
    }

    &__num {
        font-weight: 600;
        font-size: 24px;
        transition: 0.3s ease;
        opacity: 0.4;

        margin-right: ac(21px, 10px);

        /*&:nth-last-child(2) {
            margin-right: 0;
        }*/

        &.active {
            opacity: 1;
        }

        &:not(.active) {
            cursor: pointer;

            &:hover {
                transform: translateY(-5px);
            }
        }
    }

    &__arrow {
        cursor: pointer;

        &:first-of-type {
            transform: rotate(-180deg);

            position: absolute;
            right: calc(100% + ac(40px, 18px));
        }

        &:last-of-type {
            position: absolute;
            left: calc(100% + ac(40px, 18px));
        }
    }
}

/* Swiper Pagination */
:root {
    --swiper-pagination-bullet-size: 10px;
    --swiper-pagination-color: var(--black);
    --swiper-pagination-bullet-inactive-opacity: 0.5;
    --swiper-pagination-bullet-horizontal-gap: 7.5px;
}

.swiper-pagination {
    &:not(.swiper-pagination-bullets-dynamic) {
        position: static;
        text-align: left;
        margin-top: 45px;

        .swiper-pagination-bullet {
            transition: 0.3s ease-in-out;

            &-active {
                size: 12px;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    &.swiper-pagination-bullets-dynamic {
        text-align: center;
        margin: * auto;
    }

    &.solutions-pagination {
        margin-top: 30px;
        text-align: center;
    }
}

/* Common sections */
.purpose,
.vision {
    padding-top: var(--pad-md);

    &__content {
        max-width: 691px;
        flex: 1;
    }

    &__title {
        margin-bottom: 30px;
    }

    &__img {
        width: 100%;
        flex: 1;
    }

    .btn {
        margin-top: 40px;
    }

    @mixin tab-md {
        &__wrap {
            flex-direction: column;
            align-items: flex-start;
        }

        &__img {
            max-width: 80% !important;
        }
    }

    @mixin mob-xl {
        &__img {
            max-width: 100% !important;
        }
    }
}

.purpose {
    &__img {
        @mixin aspect-ratio 477, 377;
        max-width: 477px;
    }

    &__wrap {
        @mixin tab-md {
            flex-direction: column-reverse;
        }
    }
}

.vision {
    /*padding-bottom: 60px;*/

    &__img {
        @mixin aspect-ratio 436, 416;
        max-width: 436px;
    }
}

.team-section {
    padding: 60px 0 var(--pad-md) 0;

    &__title {
        max-width: 460px;
    }

    &__text {
        max-width: 498px;
    }

    .team-swiper {
        margin-top: 40px;
    }

    @mixin min-media 1700 {
        &__slider {
            .cont {
                max-width: 1610px;
            }
        }
    }
}

.team-list {
    padding: var(--pad-md) 0;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        width: 2148px;
        height: 1867px;
        top: -48%;
        left: -55px;
        z-index: -1;
        background: url("../images/bridge-light.svg") no-repeat center / contain;
    }

    &__wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 28px;
    }

    .team-card {
        max-width: 100%;
        min-height: ac(525px, 420px, 1024);
    }

    @mixin media 1201 {
        &__wrap {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @mixin tab-md {
        padding-top: 120px;
    }

    @mixin media 651 {
        &__wrap {
            grid-template-columns: 1fr;
        }
    }
}

.join-section {
    padding-bottom: var(--pad-md);

    &__title {
        margin-bottom: 50px;
    }

    &__wrap {
        gap: 57px;
    }

    &__img {
        @mixin aspect-ratio 493, 388;
        max-width: 493px;
        width: 100%;
    }

    &__img,
    &__content {
        flex: 1;
    }

    .content-element {
        margin-bottom: 40px;
    }

    @mixin tab-md {
        &__wrap {
            flex-direction: column;
            align-items: flex-start;
        }

        &__img {
            max-width: 600px;
        }
    }
}

.employers {
    padding-bottom: 60px;

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        max-height: 900px;
        overflow: hidden;
        z-index: -1;
        background: url("../images/bridge-light.svg") no-repeat, var(--white);
        background-position: 9% 0;
        background-size: 150%;
    }

    .content-element {
        p {
            line-height: 168%;
        }
    }

    &__img {
        @mixin aspect-ratio 479, 490;
        width: 100%;
        max-width: 478px;
    }

    .btn {
        margin-top: 40px;
    }

    &__content {
        max-width: 650px;
        padding-top: 112px;
        flex: 1;
    }

    &__title {
        max-width: 479px;
        margin-bottom: 44px;
    }
}

.candidates {
    padding-bottom: var(--pad-md);

    .content-element {
        p {
            line-height: 168%;
        }
    }

    &__img {
        @mixin aspect-ratio 537, 550;
        width: 100%;
        max-width: 537px;
        flex: 1;
    }

    ul {
        max-width: 514px;
    }

    .btn {
        margin-top: 40px;
    }

    &__content {
        max-width: 591px;
        flex: 1;
    }

    &__title {
        max-width: 427px;
        margin-bottom: 43px;
    }
}

.candidates,
.employers {
    &__wrap {
        gap: 60px;
    }

    @mixin tab {
        &__wrap {
            flex-direction: column;
        }

        &__img {
            max-width: 80%;
        }

        &__content,
        ul {
            max-width: 100%;
            padding-top: 0;
        }
    }

    @mixin mob-xl {
        &__img {
            max-width: 100%;
        }
    }
}

.quote-section {
    padding: ac(150px, 100px) 0 ac(144px, 100px);
    color: var(--white);

    background: url("../images/bridge-mirror.svg") no-repeat, var(--black);
    background-position: 98% 0;
    background-size: 170%;
    background-attachment: fixed;

    @mixin tab-md {
        background-position: 0 0;
        background-size: cover;
    }

    .quote-block {
        text-align: center;
        max-width: 1026px;
        margin: 0 auto;

        &:before {
            content: "";
            display: block;
            width: ac(76px, 40px);
            height: ac(76px, 40px);
            margin: 0 0 10px 20px;

            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            background-image: url('data:image/svg+xml,<svg width="76" height="64" viewBox="0 0 76 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M32.8367 0.671875C19.3693 1.6182 0.01064 3.76837 0 30.1436V63.3284H30.0519V27.8231H20.0732C19.4411 18.3425 27.2479 15.8945 35.7374 14.0153L32.8367 0.671875ZM73.0992 0.671875C59.6318 1.6182 40.2732 3.76843 40.2626 30.1436V63.3284H70.3146V27.8231H60.3359C59.7036 18.3425 67.5105 15.8945 76 14.0153L73.0992 0.671875Z" fill="%2332FDBA"/></svg>');
        }

        &__text {
            font-weight: 400;
            font-style: italic;
            font-size: ac(30px, 24px);
            margin-bottom: 52px;
            line-height: 172%;
        }

        &__author {
            font-weight: 500;
            font-size: ac(24px, 22px);
            min-height: 36px;
            color: var(--green);
        }
    }
}

.staff-section {
    padding: var(--pad-md) 0 65px;

    &__img {
        @mixin aspect-ratio 550, 374;
        width: 100%;
        max-width: 550px;
        flex: 1;
    }

    &__info {
        flex: 1;
    }

    &__title {
        max-width: 427px;
        margin-bottom: 22px;
        min-height: ac(98px, 75px);
        font-size: ac(40px, 30px);
    }

    &__subtitle {
        font-size: ac(30px, 28px);
    }

    &__desc {
        margin: 26px 0 30px;
    }

    @mixin tab-md {
        &__wrap {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.blogs,
.job-content {
    padding: 60px 0 var(--pad-md);
}

.job-content {
    .btn {
        margin-top: 47px;
    }
}

.blog-content {
    padding: 96px 0 var(--pad-md);
    overflow: visible;

    .cont {
        max-width: 1000px;
    }

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        max-height: 1847px;
        top: 0;
        left: 0;
        z-index: -1;
        background: url("../images/bridge-grad.svg") no-repeat center / contain;
    }

    &__img {
        @mixin aspect-ratio 627, 321;
        max-width: ac(627px, 385px, 770);
        position: absolute;
        bottom: calc(100% - 60px);
        width: 100%;
        right: 0;
        /*right: ac(-220px, -45px, 770);*/
    }

    @mixin tab-sm {
        padding-top: 0;

        &__img {
            position: relative;
            bottom: auto;
            right: auto;
            margin: -20px auto 40px;
            max-width: 500px;
        }
    }

    @mixin min-media 1920 {
        .cont {
            position: relative;
        }

        &__img {
            bottom: calc(100% + 30px);
            right: -22%;
        }
    }
}

.latest {
    padding-bottom: var(--pad-md);

    &__title {
        font-size: ac(44px, 24px);
    }

    &__wrap {
        margin-bottom: 50px;
    }

    .sector-card {
        height: auto;
    }

    @mixin media 415 {
        &__wrap {
            flex-direction: column;

            .btn {
                width: fit-content;
            }
        }
    }
}

.origins {
    overflow: hidden;
    padding: 320px 0 var(--pad-md);
    background: url("../images/bridge-svg.svg") no-repeat, var(--black);

    background-size: 2592px;
    background-position: 20% 87%;

    &__img {
        @mixin aspect-ratio 918, 405;
        width: 100%;
        max-width: 918px;
        position: absolute;
        top: 0;
        right: 0;

        @mixin media 1441 {
            max-width: vw(918);
        }
    }

    &__title {
        margin-bottom: 50px;
    }

    .origins__info {
        max-width: vw(1075);

        .content-element {
            p {
                font-size: ac(20px, 18px);
                font-weight: 500;
            }
        }
    }

    &:before {
        content: "";
        position: absolute;
        width: vw(140);
        height: vw(482);

        max-width: 140px;
        max-height: 482px;

        background: var(--green);
        right: 0;
        top: 133px;
        z-index: 5;
        pointer-events: none;

        min-height: 125px;
        min-width: 36px;
    }

    @mixin media 901 {
        padding-top: ac(320px, 205px, 375, 651);

        &__img {
            max-width: 670px;
            right: -25px;
        }

        &__title {
            max-width: calc(100% - 30px);
        }

        .origins__info {
            max-width: 100%;
        }
    }
}

.grecaptcha-badge {
    z-index: 200 !important;
    bottom: ac(190px, 182px) !important;
    width: 246px;
    opacity: 0 !important;
    pointer-events: none !important;
}
