.solutions {
    background: var(--black);
    padding: var(--pad-md) 0;
    color: var(--white);

    & + .blogs-section {
        margin-top: 0;
    }

    &__wrap {
        gap: 20px;
        justify-content: space-between;
    }

    &__title {
        margin-bottom: 40px;
    }

    .btn {
        width: fit-content;
    }

    .solutions-list {
        flex: 1;
        width: 100%;
        max-width: 705px;
        margin-top: 5px;

        &__item {
            position: relative;
            padding: 30px 0;

            display: flex;
            align-items: flex-start;
            gap: ac(80px, 20px);

            &:before {
                content: "";
                width: 0;
                position: absolute;
                top: 0;
                left: 0;
                height: 1px;
                background: var(--white);
                transition: width 0.5s linear;
            }

            &:last-of-type {
                &:after {
                    content: "";
                    width: 0;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 1px;
                    background: var(--white);
                    transition: width 0.5s linear;
                }
            }

            &.animated {
                &:before {
                    width: 100%;
                }

                &:last-of-type {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }

        &__num {
            font-size: 20px;
            font-weight: 500;
            color: var(--green);
        }

        &__content {
            max-width: 603px;

            h3 {
                margin-bottom: 14px;
                color: var(--green);
            }
        }
    }

    @mixin tab-md {
        padding-top: 57px;

        &__title {
            margin-bottom: 0;
        }

        &__wrap {
            flex-direction: column;
            gap: 32px;
        }

        .solutions-list__item {
            justify-content: flex-start;

            &:last-of-type {
                padding-bottom: 0;

                &:after {
                    display: none;
                }
            }
        }

        .solutions-list__content h3 {
            margin-bottom: 8px;
        }
    }
}

.solutions-choice {
    padding-bottom: var(--pad-md);

    &__title {
        text-decoration: underline;
        font-size: 30px;
        margin-bottom: ac(70px, 50px);
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        gap: ac(60px, 120px);
    }

    .btn {
        margin-top: 42px;
    }

    @mixin media 1023 {
        .btn {
            margin-top: 125px;
        }
    }
}

.solutions-block {
    &__num {
        font-size: 15px;
        font-weight: 700;
        display: inline-block;
        margin-bottom: 12px;
    }

    &__title {
        font-size: ac(30px, 28px);
        margin-bottom: 18px;
    }

    &__content {
        color: var(--white);
        padding: ac(60px, 25px);
        margin-top: 40px;
        background: var(--black);

        position: relative;
    }

    &__desc {
        line-height: 168%;
    }

    &__img {
        @mixin aspect-ratio 478, 295;
        max-width: ac(478px, 385px, 1024);
        width: 100%;

        position: absolute;
        top: -28px;
        right: -44px;
    }

    &__info {
        max-width: 59%;
        flex: 1;

        h3 {
            color: var(--green);
            font-size: ac(24px, 22px);
            margin-bottom: 20px;
        }
    }

    &:last-child {
        .solutions-block__img {
            top: 31px;
        }
    }

    @mixin media 1023 {
        &:last-child {
            .solutions-block__img {
                top: auto;
            }
        }

        &__info {
            max-width: 100%;
        }

        &__img {
            top: auto;
            right: auto;
            position: relative;
            margin: 40px auto -90px;
            max-width: 100%;
        }
    }
}
