.sectors-section {
    padding: var(--pad-main) 0 ac(120px, 60px, 1024);

    & + .blogs-section {
        margin-top: 0;
    }

    &__title {
        margin-bottom: ac(33px, 27px);
        max-width: 628px;
    }

    .cont {
        position: relative;
    }

    .sector-names,
    .sector-names__item {
        display: flex;
        align-items: center;
    }

    .sector-names {
        gap: ac(40px, 30px);
        margin-bottom: 40px;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        &__item {
            gap: 10px;
            white-space: nowrap;
            color: var(--black);
            overflow: visible;

            &:before {
                left: auto;
                right: 0;
            }

            &.showed {
                .sector-names__color {
                    transform: scale(1);
                }
            }

            &:hover {
                .sector-names__color {
                    transform: scale(1.2);
                }

                &:before {
                    width: calc(100% - 24px);
                }
            }
        }

        &__color {
            border-radius: 50%;
            background: var(--green);
            width: 14px;
            height: 14px;
            flex-shrink: 0;
            transform: scale(0);
            transition: transform 0.3s ease;
        }

        &__txt {
            font-size: 15px;
            font-weight: 400;
            transition: color 0.3s ease;
        }
    }

    .sectors-swiper {
        margin-bottom: 36px;
        overflow: visible;

        .swiper-slide {
            height: auto;
        }
    }

    @mixin mob-lg {
        .swiper-navigation {
            position: absolute;
            top: 20px;
            right: 0;
        }

        &__title {
            max-width: calc(100% - 150px);
        }
    }

    @mixin media 360 {
        .swiper-navigation {
            top: 0;
        }

        &__title {
            max-width: 100%;
        }

        .subtitle {
            margin-bottom: 30px;
        }
    }
}
