.btn {
    font-size: ac(16px, 15px);
    font-weight: 500;
    color: var(--black);
    background: var(--green);
    padding: 12px 63px 12px 17px;

    display: inline-flex;
    align-items: center;
    gap: 15px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    transition: 0.3s ease;

    &:after,
    &:before {
        content: "\e900";
        font-family: "icomoon";
        color: var(--black);
        font-size: 30px;
        line-height: 0.5;
        transition: 0.3s ease-in-out;
        position: absolute;
    }

    &:before {
        transform: translateX(-50%);
        position: absolute;
        left: -25%;
    }

    &:after {
        right: 17px;
    }

    span {
        transition: 0.3s ease 0.3s;
    }

    &.btn-white {
        background: var(--white);
        color: var(--black);

        &:after,
        &:before {
            color: var(--black);
        }
    }

    &.btn-black {
        color: var(--white);
        background: var(--black);

        &:after,
        &:before {
            color: var(--white);
        }
    }

    &:hover {
        box-shadow: 0 0 30px rgb(50 253 186 / 45%);

        &:before {
            left: 50%;
        }

        &:after {
            right: -50%;
        }

        span {
            opacity: 0 !important;
            transition-delay: 0s;
        }
    }

    &.btn-animation {
        span {
            opacity: 0;
            font-size: 0;
            transition: all 0.3s ease;
            display: inline-block;

            @mixin min-media 768 {
                white-space: nowrap;
            }

            &.animated {
                opacity: 1;
                order: -1;
                font-size: ac(16px, 15px);
            }
        }
    }
}

.arrow-btn {
    background: var(--black);
    color: var(--white);
    border-radius: 5px;
    width: ac(62px, 54px);
    height: ac(40px, 34px);
    font-size: 30px;
    transition: 0.3s ease;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid var(--black);
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        left: -5px;
        bottom: 100%;
        height: 120%;
        width: 120%;
        background: var(--white);
        transition: 0.3s ease;
        pointer-events: none;
        border-radius: 5px;
    }

    i {
        position: relative;
        z-index: 5;
    }

    &.white {
        background: var(--white);
        color: var(--black);
        border: none;

        &:before {
            background: var(--black);
        }
    }

    &:hover {
        color: var(--black);

        &:before {
            bottom: -5px;
        }

        &.white {
            color: var(--white);
            background: var(--black);
        }
    }
}

.back-btn {
    display: inline-flex;
    align-items: center;
    padding: 12px 17px;
    color: var(--white);
    font-weight: 500;
    font-size: 16px;
    transition: color 0.3s ease;
    margin-bottom: 17px;

    i {
        color: var(--green);
        transform: rotate(-180deg);
        margin-right: 20px;
        font-size: 30px;
        transition: 0.3s ease-in-out;
    }

    &:hover {
        color: var(--green);

        i {
            transform: rotate(-180deg) translateX(10px);
        }
    }
}

.swiper-navigation {
    display: flex;
    gap: 20px;

    .swiper-btn-prev,
    .swiper-btn-next {
        &.swiper-button-lock {
            display: none;
        }
    }

    .swiper-btn-prev {
        transform: scale(-1);
    }
}
