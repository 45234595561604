.benefits {
    &__title {
        max-width: 697px;
        margin-bottom: 30px;
    }

    &__wrap {
        gap: vw(96);
    }

    &__img {
        @mixin aspect-ratio 420, 390;
        max-width: 420px;
        width: 100%;
        flex: 1;
        margin-bottom: -88px;
    }

    &__content {
        max-width: 603px;
        flex: 1;
        margin-top: 26px;

        .btn {
            margin-top: 40px;
        }
    }

    /*&__desc {

		.simplebar-scrollbar::before {
			background-color: var(--white);
		}
	}*/

    &__list {
        padding: 150px 0 ac(135px, 60px);
        background: var(--black);
        color: var(--white);

        &-wrap {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 32px;
        }
    }

    &__icon {
        size: 70px;
        margin-bottom: 20px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__name {
        font-size: ac(28px, 24px);
        font-weight: 700;
        margin-bottom: 10px;
    }

    @mixin tab-sm {
        &__wrap {
            flex-direction: column-reverse;
            gap: 40px;
            align-items: flex-start;
        }

        &__content {
            margin-top: 0;
        }

        &__list-wrap {
            grid-template-columns: 1fr;
        }
    }
}
