.hero-screen {
    color: var(--white);
    overflow: hidden;

    &,
    &__bg {
        background: var(--black);
        background-image: url("../images/bridge-svg.svg");
        background-size: 150%;
        background-position: 8% 0;
        background-repeat: no-repeat;
        background-attachment: fixed;
    }

    &__text {
        max-width: 603px;

        &,
        p {
            font-weight: 400;
            font-size: ac(24px, 18px);
        }
    }

    &__title {
        margin-bottom: 40px;
    }

    &__intro {
        font-size: ac(24px, 22px);
    }

    &__wrap {
        padding: 40px 0;
        color: var(--black);
    }

    &__form {
        .input-item {
            /*margin-left: auto;*/
            max-width: 603px;
            background: var(--white);
            color: var(--black);
            padding: 14px 80px 14px ac(30px, 14px);

            input {
                line-height: 1;
            }

            .arrow-btn {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }

            @mixin min-media 1920 {
                max-width: 700px;
            }
        }
    }

    &__caption {
        min-height: 100px;

        .dot {
            font-size: ac(60px, 32px);
        }
    }

    /* Pages */
    &--main,
    &--hiring,
    &--jobs {
        background-image: none;
    }

    &--main {
        .hero-screen__wrap {
            color: var(--black);
            margin-top: auto;
            padding: 147px 0 50px;
            background: var(--green);
        }

        .content-element {
            p {
                font: 600 var(--font-sec) ac(20px, 18px) / 160% !important;
            }
        }

        .hero-screen__form {
            margin-bottom: 20px;
        }

        .hero-screen__caption {
            margin-bottom: 20px;
        }

        .hero-screen__heading {
            max-width: 956px;
            position: relative;
            z-index: 5;
        }

        .hero-screen__title {
            max-width: 943px;
            font-size: ac(60px, 32px);
            font-weight: 500;
            display: inline;
        }

        .hero-screen__text {
            font-size: ac(20px, 18px);
            font-weight: 600;
            margin-bottom: 40px;
            max-width: 956px;
        }

        .hero-screen__img {
            @mixin aspect-ratio 1120, 426;
            width: 100%;
            max-width: 1120px;
            position: absolute;
            left: -9.5%;
            bottom: calc(100% - 30px);
            transition: 0.4s ease;
            min-height: 200px;

            img,
            video,
            iframe {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .hero-screen__bg {
            min-height: ac(445px, 200px, 651);
            background-position: 8% 22%;
        }

        .btn {
            font-size: ac(25px, 15px);
            min-height: ac(61.5px, 50px);

            span {
                &.animated {
                    font-size: ac(25px, 15px);
                }
            }
        }
    }

    &--about {
        background-size: 150%;
        background-position: 8% 0;

        .cont {
            padding: ac(340px, 120px) 0 ac(160px, 400px, 768);
        }

        .hero-screen__heading {
            max-width: 70%;
        }

        .hero-screen__title {
            font-size: ac(80px, 32px);
            margin-bottom: 44px;
            max-width: 17ch;
            min-height: ac(194px, 80px);
        }

        .hero-screen__img {
            @mixin aspect-ratio 545, 527;
            width: vw(545);
            max-width: 545px;
            min-width: 380px;

            position: absolute;
            right: -15%;
            bottom: 0;
        }
    }

    &--join {
        .cont {
            &.relative {
                padding: ac(402px, 180px) 0 266px;
            }
        }

        .hero-screen__title {
            margin-bottom: 0;
            font-size: ac(64px, 40px, 901);
            min-height: ac(232px, 110px);
            max-width: 18ch;
        }

        .hero-screen__heading {
            max-width: 63.5%;
        }

        .hero-screen__img-wrap {
            height: calc(100% + 5px);
            max-width: ac(580px, 250px, 551);
            width: 100%;
            position: absolute;
            bottom: -5px;
            left: 63.5%;
        }

        .decor {
            position: absolute;
            width: ac(202px, 125px);
            height: ac(193px, 118px);
            background: var(--green);
            bottom: 0;
            right: calc(100% - 31px);
        }

        .hero-screen__img {
            mix-blend-mode: exclusion;
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0;
            right: 0;

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0.6) 0%,
                    rgba(0, 0, 0, 0) 100%
                );
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &--hiring {
        .hero-screen__bg {
            min-height: ac(361px, 300px);
            background-position: 8% 21%;
        }

        .hero-screen__wrap {
            padding: ac(74px, 120px, 1024) 0 ac(128px, 100px);
            background: var(--green);
        }

        .hero-screen__heading {
            max-width: 720px;
        }

        .hero-screen__title {
            font-size: ac(64px, 32px);
            margin-bottom: 48px;
            max-width: 16ch;
            min-height: ac(232px, 118px);
        }

        .input-item {
            margin-left: 0;
        }

        .hero-screen__img {
            @mixin aspect-ratio 751, 390;
            max-width: ac(751px, 520px, 768);
            width: 100%;
            position: absolute;
            top: ac(-228px, -320px, 1024);
            right: -22.5%;
        }
    }

    &--solutions {
        .hero-screen__bg {
            padding: ac(230px, 150px) 0 ac(108px, 80px);
        }

        .hero-screen__wrap {
            padding-top: 64px;
            padding-bottom: 0;
            overflow: hidden;
            z-index: 10;
            position: relative;

            .cont {
                min-height: 300px;
            }

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: var(--green);
                top: 0;
                left: 0;
                max-width: vw(1200);
            }
        }

        .hero-screen__title {
            margin-bottom: 0;
            max-width: 16ch;

            min-height: ac(170px, 85px);

            font-size: ac(70px, 32px);

            @mixin media 360 {
                font-size: 28px;
            }
        }

        .solutions-swiper {
            max-width: vw(1100);
            overflow: hidden;
            padding-top: 10px;
        }

        .hero-screen__img {
            height: 100%;
            width: 100%;

            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 6;
            pointer-events: none;

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: #020202;
                z-index: 5;
                mix-blend-mode: soft-light;
            }

            video,
            iframe,
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .hero-screen__solution {
            max-width: 267px;
            padding-bottom: 20px;
            position: relative;
            height: auto;

            display: flex;
            flex-direction: column;

            /*&:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 19px;
                bottom: 0;
                left: 0;
                transition: height 0.3s ease-in-out;
                background: var(--black);
            }*/

            &-title {
                font-size: 22px;
                font-weight: 500;
                line-height: 130%;
                min-height: 57px;
                transition: transform 0.3s ease-in-out;

                @mixin media 551 {
                    min-height: auto;
                }
            }

            &-inner {
                margin-top: 20px;
                min-height: 176px;
                padding-top: 15px;
                border-top: 2px solid var(--black);

                display: flex;
                flex-direction: column;
                height: 100%;

                .btn {
                    margin-top: auto;
                }

                @mixin media 551 {
                    min-height: auto;
                }
            }

            &-desc {
                font-size: ac(17px, 15px);
                font-weight: 400;
                font-family: var(--font-sec);
                margin-bottom: 20px;
            }

            .btn {
                width: fit-content;
                transform: translateY(100%);
                opacity: 0;
            }

            &:hover {
                &:after {
                    height: 0;
                }

                .hero-screen__solution-title {
                    transform: translateY(-10px);
                }

                .btn {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    }

    &--jobs {
        padding: ac(241px, 150px) 0 87px;
        text-align: center;

        .hero-screen__title {
            margin-bottom: 40px;
        }

        .hero-screen__heading {
            display: flex;
            flex-direction: column;
            align-items: center;

            position: relative;
            z-index: 5;
        }

        .hero-screen__form {
            width: 100%;

            .input-item {
                padding: 17px *;
                margin: 0 auto;

                .focus-border {
                    &:before,
                    &:after,
                    i:before,
                    i:after {
                        background: var(--green);
                    }
                }
            }
        }

        .hero-screen__text {
            margin-bottom: 60px;
            max-width: 687px;
        }
    }

    &--details {
        padding: 150px 0 54px;
        background-position: -11% 18%;
        background-size: 135%;

        .hero-screen__heading {
            max-width: 1016px;
            margin-bottom: 42px;
        }

        .hero-screen__title {
            margin-bottom: 20px;
        }

        .hero-screen__text {
            margin-bottom: 20px;
            min-height: 38px;
        }

        .details {
            gap: 35px;
            padding-left: ac(6px, 0px);
            align-items: flex-start;
            flex-wrap: wrap;
            display: flex;

            &__item {
                display: flex;
                align-items: center;
            }

            &__item {
                gap: 12px;
                font-size: 18px;
                font-weight: 400;
                line-height: 125%;
                font-family: var(--font-sec);

                i {
                    flex-shrink: 0;
                    color: var(--green);
                }
            }
        }
    }

    &--blog {
        padding: 150px 0 75px;
        background-position: -20% 17%;
        background-size: 130%;

        .cont {
            max-width: 1000px;
        }

        .hero-screen__title {
            margin-bottom: 19px;
        }

        .hero-screen__text {
            min-height: 38px;
        }

        .hero-screen__heading {
            margin-bottom: 30px;
        }

        .blog-details {
            display: flex;
            align-items: center;
            margin-bottom: 34px;
            max-width: 55%;
            flex-wrap: wrap;
            grid-row-gap: 8px;

            li {
                display: flex;
                align-items: center;
                font-size: 18px;
                font-family: var(--font-sec);

                &:not(:last-child) {
                    &:after {
                        display: block;
                        content: "";
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background: var(--green);
                        margin: 0 12px;
                    }
                }
            }

            @mixin tab {
                max-width: 50%;
            }

            @mixin media 901 {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                max-width: 100%;

                li {
                    padding-left: 12px;
                    position: relative;

                    &:not(:last-child) {
                        &:after {
                            display: block;
                            content: "";
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background: var(--green);
                            margin: 0;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                        }
                    }

                    &:after {
                        display: block;
                        content: "";
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        background: var(--green);
                        margin: 0;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    &--blogs {
        padding: ac(240px, 150px) 0 var(--pad-md);
        z-index: 10;
        overflow: visible;
        background-position: -11% 16%;
        background-size: 135%;

        .hero-screen__title {
            margin-bottom: 60px;
            text-align: center;
        }

        .blogs-form {
            background: var(--white);
            padding-right: 122px;
            position: relative;

            display: flex;
            max-width: 792px;
            margin: 0 auto;

            .input-item {
                padding: 14px 18px;
                width: 100%;

                .focus-border {
                    &:before,
                    &:after,
                    i:before,
                    i:after {
                        background: var(--green);
                    }
                }
            }

            .choices {
                width: 100%;
                max-width: 253px;
            }

            .arrow-btn {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    /* Responsive */
    @mixin min-media 1920 {
        &--join {
            .hero-screen__img-wrap {
                left: 60%;
            }
        }
    }

    @mixin media 1201 {
        &--about {
            .hero-screen__heading {
                max-width: 100%;
            }
        }
    }

    @mixin media 1025 {
        &--solutions {
            .solutions-swiper {
                overflow: visible;
                max-width: 100%;
            }

            .hero-screen__solution {
                padding-bottom: 40px;

                .btn {
                    opacity: 1;
                    transform: translateY(0);
                }

                &:after {
                    display: none;
                }
            }

            .hero-screen__bg {
                position: relative;
            }

            .hero-screen__wrap:before {
                max-width: 100%;
            }
        }
    }

    @mixin media 901 {
        &--join {
            overflow: visible;
            padding-bottom: 20px;

            .hero-screen__heading {
                max-width: 100%;
            }

            .hero-screen__title {
                font-size: ac(40px, 30px, 375, 901);
            }

            .cont.relative {
                padding-bottom: 0;
            }

            .hero-screen__img-wrap {
                position: relative;
                max-width: 360px;
                margin: 40px auto -75px;
                bottom: auto;
                left: auto;

                .hero-screen__img {
                    position: relative;
                    mix-blend-mode: normal;
                }
            }
        }
    }

    @mixin media 767 {
        &,
        &__bg {
            background-size: cover;
        }
    }

    @mixin media 651 {
        &--solutions {
            .hero-screen__wrap {
                padding-top: 40px;
                padding-bottom: 40px;

                .cont {
                    min-height: 0;
                }
            }

            .hero-screen__solution {
                padding-bottom: 0;

                .hero-screen__solution-inner {
                    min-height: fit-content;
                }

                &:after {
                    display: none;
                }
            }
        }

        &--main {
            .cont {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
            }

            .hero-screen__wrap {
                padding-top: 0;
            }

            .hero-screen__img {
                position: relative;
                bottom: auto;
                left: 0;
                width: 100vw;
                right: 0;
                margin-bottom: -25px;
            }

            .hero-screen__bg {
                min-height: 120px;
            }
        }
    }

    @mixin mob-lg {
        &__bg {
            svg {
                left: -48%;
                top: -3%;
                width: 930px;
                height: 808px;
            }
        }

        &--join {
            .hero-screen__img {
                height: 50%;
            }
        }

        &--about {
            .cont {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                padding-bottom: 120px;
            }

            .hero-screen__heading {
                align-self: flex-start;
            }

            .hero-screen__img {
                position: relative;
                bottom: auto;
                min-width: 310px;
                width: 100vw;
                right: 0;
                left: 0;
                margin-bottom: 40px;
            }
        }

        &--hiring {
            .hero-screen__wrap {
                padding-top: 60px;
            }

            .hero-screen__img {
                width: 100vw;
                right: 50%;
                transform: translateX(50%) !important;
                top: auto;
                bottom: calc(100% + 20px);
            }
        }

        &--details {
            background-size: cover;
        }

        &--blogs {
            .blogs-form {
                padding-right: 0;
                flex-direction: column;

                .input-item {
                    padding-right: 70px;
                }

                .choices {
                    max-width: 100%;

                    .choices__inner {
                        border: none;
                        border-top: 1px solid var(--grey);
                        padding-left: 18px !important;
                    }
                }

                .arrow-btn {
                    transform: unset;
                    top: 8px;
                }
            }
        }

        &--solutions {
            .hero-screen__solution {
                max-width: 100%;
            }
        }
    }
}
