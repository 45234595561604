.footer {
    background: var(--black);
    color: var(--white);
    padding: ac(110px, 60px, 1024) 0 ac(36px, 30px, 1024);

    &__title {
        font-size: ac(120px, 40px);
        margin-bottom: ac(52px, 36px);
        font-weight: 400;
    }

    &__form {
        padding-bottom: 60px;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 1px;
            background: var(--white);
            left: 0;
            bottom: 0;
            transition: 0.6s linear;
        }

        &.showed {
            &:before {
                width: 100%;
            }
        }

        &-wrap,
        .input-item {
            display: inline-flex;
            column-gap: 30px;
            row-gap: 22px;
        }

        .input-item {
            width: fit-content;
            align-items: flex-start;
            flex-grow: 1;
        }

        &-wrap {
            flex-wrap: wrap;
            margin-bottom: ac(18px, 30px);

            .input-item {
                &:first-of-type {
                    max-width: 447px;
                }

                &:nth-of-type(2) {
                    max-width: 512px;
                }

                &:nth-of-type(3) {
                    max-width: 357px;
                }

                &:last-of-type {
                    .focus-line {
                        bottom: 7px;
                    }
                }
            }
        }

        .btn {
            display: flex;
            width: fit-content;
            margin-left: auto;
        }

        label {
            font-size: ac(20px, 16px);
            font-weight: 500;
            flex-shrink: 0;
        }

        input,
        textarea {
            color: var(--white);
            background: transparent;
            border-bottom: 1px solid var(--grey);
            padding: 0 0 17px;
            height: 48px;
            @mixin transparent-bg-input var(--white);

            &::placeholder {
                color: rgba(255, 255, 255, 0.7);
            }
        }
    }

    &__bottom-info {
        margin-top: 24px;
        gap: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__contacts {
        display: flex;
        flex-wrap: wrap;
        column-gap: 40px;
        row-gap: 20px;
        max-width: 390px;
    }

    .footer__socials {
        /*display: none;

        @mixin media 1380 {
            display: flex;
        }*/
        margin-right: 180px;

        @mixin media 991 {
            margin-right: 0;
        }
    }

    @mixin media 991 {
        .input-item {
            max-width: 100% !important;
            width: 50%;
        }
    }

    @mixin mob-lg {
        .input-item {
            width: 100%;
            flex-direction: column;
            gap: 0;

            input {
                padding-bottom: 0;
            }

            textarea {
                margin-top: 11px;
                height: 42px;
            }
        }

        &__bottom-info {
            margin-top: 27px;
        }

        &__contacts {
            row-gap: 14px;
        }

        .btn {
            margin-left: 0;
        }

        .footer__form-wrap {
            row-gap: 17px;
        }

        &__bottom-info {
            align-items: flex-start;
        }
    }

    @mixin media 451 {
        .contact-link {
            max-width: 265px;
        }
    }
}
