.job-board {
    padding: 60px 0 var(--pad-md);
    overflow: visible;
    min-height: 715px;

    .cont {
        position: relative;
        z-index: 5;
    }

    &:before {
        content: "";
        position: absolute;
        width: ac(193px, 100px);
        height: ac(385px, 170px, 768);
        background: var(--green);
        right: 0;
        top: -135px;
    }

    .sidebar {
        max-width: 276px;
    }

    .sidebar__inner {
        position: sticky;
    }

    &__count {
        font-size: 18px;
        margin-bottom: 30px;
    }

    &__wrapper {
        display: flex;
        gap: 30px;
        justify-content: center;
    }

    &__filters {
        /*overflow: hidden;*/

        display: flex;
        flex-direction: column;
        gap: 24px;

        .filter-box.open {
            &:nth-child(3),
            &:nth-child(4) {
                .filter-box__head {
                    margin-bottom: 18px;
                }
            }
        }
    }

    &__pagination {
        margin-top: 60px;
    }

    &__result,
    .sidebar {
        width: 100%;
        flex: 1;
    }

    &__result {
        max-width: 914px;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;
        grid-auto-rows: 1fr;
    }

    @mixin media 1280 {
        &__list {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @mixin media 991 {
        &__list {
            grid-template-columns: 1fr;
        }
    }

    @mixin tab-sm {
        &__wrapper {
            flex-direction: column;
        }

        .sidebar {
            max-width: 100%;
        }

        &__list {
            grid-template-columns: repeat(2, 1fr);
        }

        &__filters,
        &__result {
            max-width: 100%;
            width: 100%;
        }
    }

    @mixin mob-lg {
        &__list {
            grid-template-columns: 1fr;
        }
    }
}

.filter-box {
    padding-bottom: 29px;
    border-bottom: 1px solid var(--black);
    padding-left: 2px;

    &:last-child {
        border: none;
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        transition: margin 0.3s ease;
        cursor: pointer;

        h3 {
            font-weight: 600;
            font-size: 24px;
            transition: color 0.3s ease;
        }

        &:hover {
            h3 {
                color: var(--green);
            }
        }
    }

    [data-simplebar] {
        margin-right: 0;

        .simplebar-track.simplebar-vertical {
            width: 8px;
        }
        
        @mixin tab-sm {
            max-height: 240px;
        }
    }

    &__checkboxes {
        display: flex;
        flex-direction: column;
        gap: 18px;
        width: 100%;
        height: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 18px;
        height: 0;
        opacity: 0;
        transition: 0.3s ease;
        pointer-events: none;
        
        @mixin tab-sm {
            max-height: 240px;
        }
    }

    &__icon {
        width: 20px;
        height: 20px;
        transition: transform 0.3s ease;
        transform: rotate(-180deg);

        background-repeat: no-repeat;
        background-size: 70%;
        background-position: center;
        background-image: url('data:image/svg+xml,<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.99999 2.828L2.04999 7.778L0.635986 6.364L6.99999 0L13.364 6.364L11.95 7.778L6.99999 2.828Z" fill="%23020519"/></svg>');
    }

    &.open {
        .filter-box__icon {
            transform: rotate(0deg);
        }

        .filter-box__content {
            opacity: 1;
            pointer-events: auto;
        }

        .filter-box__head {
            margin-bottom: 23px;
        }
    }
}
