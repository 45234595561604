.sector-card {
    background: var(--grey);
    padding: 30px 32px 24px;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    transition: background-color 0.3s ease;

    transform-style: preserve-3d;
    transform: perspective(1000px);
    height: 100%;

    p {
        font-size: 15px;
    }

    &__title {
        margin-bottom: auto;
        transform: translateZ(20px);
        line-height: 137%;
    }

    &__details {
        margin: 20px 0 15px;
        display: grid;
        gap: 10px;

        span {
            font-weight: 500;
            line-height: 135%;
        }
    }

    &__name {
        font-weight: 400;
    }

    &:hover {
        background-color: var(--green);
    }

    @mixin mob-lg {
        transform: translateZ(0px);
        transform-style: unset;

        &__title {
            line-height: 170%;
        }

        &__details {
            margin: 6px 0 18px;
        }
    }
}
