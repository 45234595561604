.clients-section {
    padding-bottom: var(--pad-md);
    margin-bottom: 40px;

    &__title {
        max-width: 397px;
    }

    &__slider {
        margin-top: ac(60px, 40px);

        .swiper-slide {
            height: 106px;
            padding: 20px 30px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .swiper-pagination {
            --swiper-pagination-bottom: 0px;

            display: none;
        }
    }

    @mixin tab {
        .swiper-navigation {
            display: none;
        }

        &__slider {
            .swiper-pagination {
                display: block;
                text-align: center;
            }
        }
    }
}
