input,
textarea {
    width: 100%;
    font-size: ac(20px, 16px);
    resize: none;
    color: var(--black);
    border-radius: 0;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--grey);
        border-radius: 8px;
    }

    &:focus {
        & ~ .focus-border {
            &:before,
            &:after {
                width: 100%;
                transition: 0.3s;
            }

            i {
                &:before,
                &:after {
                    height: 100%;
                    transition: 0.4s;
                }
            }
        }

        & ~ .focus-line {
            width: 100%;
            transition: 0.4s;
            left: 0;
        }

        &::placeholder {
            color: transparent !important;
        }
    }

    &::placeholder {
        color: var(--black);
        opacity: 0.6;
    }

    &:placeholder-shown {
        text-overflow: ellipsis !important;
    }

    @mixin transparent-bg-input var(--black);
}

textarea {
    -webkit-overflow-scrolling: touch;

    &::placeholder {
        text-overflow: ellipsis !important;
        white-space: nowrap;
        overflow: hidden;
    }
}

.input-item {
    position: relative;
}

.focus-border {
    &:after,
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: var(--black);
        transition: 0.3s;
    }

    &:after {
        top: auto;
        bottom: 0;
        left: auto;
        right: 0;
    }

    i {
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 0;
            background-color: var(--black);
            transition: 0.4s;
        }

        &:after {
            left: auto;
            right: 0;
            top: auto;
            bottom: 0;
        }
    }
}

.focus-line {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: var(--green);
    transition: 0.4s;
    pointer-events: none;
}

/* Checkbox */
.checkbox {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
    position: relative;

    span {
        padding-left: 30px;
        position: relative;
        display: block;
        flex: 0 0 auto;
        font-size: 20px;
        font-weight: 400;
        color: var(--black);
        transition: all 0.3s ease;
        word-break: break-word;
        width: 100%;
        font-family: var(--font-sec);
        line-height: normal;

        &:before {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            border: 2px solid var(--black);
            border-radius: 2px;
            left: 0;
            top: 6px;
            transition: all 0.3s ease;

            background-repeat: no-repeat;
            background-size: 0;
            background-position: center;
            background-image: url("../images/tick.svg");
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 100%;
        left: 0;
        top: 0;
        transform: translateZ(0);

        &:checked + span {
            &:before {
                border-color: #21d99c;
                background-size: 130%;
            }
        }
    }

    &:hover {
        @mixin min-media 1025 {
            span:before {
                border-color: #21d99c;
            }
        }
    }
}

/* Range */
.double-range {
    margin: 12px 0 44px;
    height: 2px;
    border: none;
    box-shadow: none;
    background: #efefef;

    .noUi-tooltip {
        transform: translate(-50%, 100%);
        left: 50%;
        bottom: -20px;
        display: block !important;
        font-size: 15px;
        font-weight: 400;
        color: var(--black);
        font-family: var(--font-sec);
        padding: 0;
        line-height: 1.2;

        border: none;

        &.hidden {
            display: none !important;
        }
    }

    .noUi-connect {
        background: var(--green);
        margin-right: -4px;
    }

    .noUi-handle {
        background: var(--green);
        width: 18px;
        height: 18px;
        border: 2px solid #21d99c;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: none;

        &:before,
        &:after {
            content: none;
        }
    }
}

/* Choices */
.choices {
    margin-bottom: 0;

    &__inner {
        padding: 20px 45px 20px 24px !important;
        height: 100%;
        border-top: none;
        border-bottom: none;
        background: var(--white);
        border-color: var(--grey);
        border-radius: 0;

        line-height: 1;
        display: flex;
        align-items: center;
    }

    .choices__placeholder {
        opacity: 1;
    }

    .choices__list--single {
        padding: 0;
        color: var(--black);
        font-size: 16px;

        .choices__item {
            @mixin max-line-length-one;
        }
    }

    &[data-type*="select-one"]::after {
        border: none;
        width: 20px;
        height: 20px;
        right: 24px;
        margin: 0 !important;
        transform: translateY(-50%);

        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.33335 7.08969L4.51669 5.8335L10 11.6544L15.4834 5.8335L16.6667 7.08969L10 14.1668L3.33335 7.08969Z" fill="%23020202"/></svg>');
    }

    .choices__list--dropdown {
        top: 100%;

        .choices__item--selectable {
            color: var(--black);

            &.is-highlighted {
                background: var(--green);
            }
        }
    }
}
