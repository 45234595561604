.team-card {
    max-width: ac(380px, 330px);
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid transparent;

    color: var(--white);
    transition: border-color 0.3s ease;
    height: auto;

    display: flex;
    flex-direction: column;

    &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        max-height: 470px;
        top: 0;
        right: 0;
        background: url("../images/team-card-bg.svg") no-repeat right / cover;
        pointer-events: none;
    }

    &__bg {
        min-height: 232px;
        background: var(--green);
    }

    &__caption {
        padding: ac(182px, 95px) 30px 30px;
        background: var(--black);
        height: 100%;
    }

    &__img {
        @mixin aspect-ratio 294, 354;

        position: absolute;
        left: 0;
        top: 30px;
        max-width: ac(294px, 230px);
        width: 100%;
        z-index: 5;

        img {
            border-radius: 0 5px 5px 0;
            object-position: top;
        }
    }

    &__desc {
        position: absolute;
        width: 100%;
        height: 0;
        left: 0;
        bottom: 0;
        background: var(--black);
        transition: 0.3s ease-in-out;
        z-index: 10;
        pointer-events: none;

        color: var(--white);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-content {
            max-width: 92%;
            width: 100%;
            text-align: center;
            opacity: 0;
            transition: 0.3s ease;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        p {
            color: var(--white);
            max-height: 240px;
        }

        h3 {
            font-size: 28px;
            margin: 8px 0 20px;
        }

        .share-block__link {
            margin-top: 20px;

            i {
                margin-left: -0.5px;
            }

            &:hover {
                i {
                    color: var(--black);
                }
            }
        }
    }

    [data-simplebar] {
        width: 100%;
        max-height: 190px;

        .simplebar-scrollbar:before {
            background-color: var(--green);
            width: 5px;
        }
    }

    p {
        font-size: 17px;
    }

    h4 {
        font-size: 18px;
        font-weight: 500;
        font-family: var(--font-sec);
        text-transform: uppercase;
        letter-spacing: 1.8px;
        color: var(--green);
    }

    &__name {
        font-weight: 500;
        font-size: ac(28px, 24px);
    }

    .share-block__link:hover {
        i {
            color: var(--green);
        }
    }

    &:hover,
    &.active {
        border-color: var(--green);

        .team-card__desc {
            height: 100%;
            pointer-events: auto;

            &-content {
                opacity: 1;
                transition-delay: 0.3s;
            }

            &:before {
                height: 100%;
            }
        }
    }

    @mixin media 551 {
        p {
            font-size: 15px;
        }

        [data-simplebar] {
            max-height: 188px;
        }
    }

    @mixin media 360 {
        max-width: 100%;
    }
}
