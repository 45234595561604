.testimonials {
    padding: var(--pad-md) 0 ac(112px, 80px);

    .cont {
        position: relative;
    }

    &__title {
        margin-bottom: 50px;
    }

    &__text {
        max-width: 565px;
        font-size: ac(18px, 15px);
        margin-bottom: 50px;
    }

    & + .solutions {
        padding-bottom: ac(80px, 60px);
    }

    --swiper-pagination-bottom: -40px;

    .swiper-pagination {
        @mixin min-media 1025 {
            &.swiper-pagination-bullets-dynamic {
                transform: translateX(0) !important;
                left: 0 !important;
            }
        }
    }
}
