@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?vtxsbe');
    src:  url('../fonts/icomoon.eot?vtxsbe#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?vtxsbe') format('truetype'),
    url('../fonts/icomoon.woff?vtxsbe') format('woff'),
    url('../fonts/icomoon.svg?vtxsbe#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
    content: "\e90a";
}
.icon-twit:before {
    content: "\e904";
}
.icon-instagram:before {
    content: "\e909";
}
.icon-facebook:before {
    content: "\e90b";
}
.icon-mail:before {
    content: "\e907";
}
.icon-phone:before {
    content: "\e908";
}
.icon-fb:before {
    content: "\e902";
}
.icon-inst:before {
    content: "\e903";
}
.icon-coin:before {
    content: "\e905";
}
.icon-suitcase:before {
    content: "\e906";
}
.icon-arrow:before {
    content: "\e900";
}
.icon-linkedin:before {
    content: "\e901";
}


h2,
h3 {
    font-weight: 500;
}

h1 {
    font-size: ac(70px, 34px);
    font-weight: 400;
    line-height: 121%;
}

h2 {
    font-size: ac(40px, 22px);
    font-weight: 500;
    line-height: 121%;
}

h3 {
    font-size: ac(25px, 20px);
    font-weight: 500;
}

p {
    font-size: ac(17px, 15px);
    font-weight: 400;
    line-height: 158%;
    font-family: var(--font-sec);
}

a {
    font-size: ac(16px, 15px);
    font-weight: 500;
    display: inline-block;
}

.content-element {
    *:not(h1, h2, h3, h4, h5, h6) {
        font-family: var(--font-sec) !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &:not(:last-child) {
            margin-bottom: 18px !important;
        }
    }

    h2 {
        font: 600 24px var(--font-main) !important;
    }

    h3 {
        font: 600 22px var(--font-main) !important;
    }

    h4 {
        font: 600 21px var(--font-main) !important;
    }

    h5 {
        font: 600 20px var(--font-main) !important;
    }

    h6 {
        font: 600 19px var(--font-main) !important;
    }

    p,
    a,
    li,
    b,
    span,
    i {
        word-break: break-word;
    }

    &:not(.md) {
        p,
        a,
        li,
        b,
        span,
        i {
            font: 400 var(--font-sec) ac(17px, 15px) / 160% !important;
        }
    }

    b {
        display: block;
        font-weight: 600;

        &:not(:last-child) {
            margin-bottom: 24px !important;
        }
    }

    img {
        margin: 42px 0 40px;
    }

    p {
        &:not(:last-child) {
            margin-bottom: 30px !important;
        }
    }

    ol {
        list-style: decimal;
    }

    a {
        text-decoration: underline;
        transition: color 0.3s ease;

        &:hover {
            color: var(--green);
        }
    }

    ul,
    ol {
        padding-left: 20px;
        margin: 0 0 30px 10px !important;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    a {
        text-decoration: underline;
        transition: color 0.3s ease;

        &:hover {
            color: var(--green);
        }
    }

    &.custom-list {
        ul {
            list-style: none;
            padding-left: 0;
            margin-left: 4px;

            li {
                position: relative;
                padding-left: 22px;
                line-height: 170%;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background: var(--green);
                    border: 2px solid var(--black);
                }
            }
        }
    }
}

.blog-content,
.job-content {
    .content-element {
        p,
        a,
        li,
        b {
            font-size: ac(18px, 15px);
            line-height: 152%;
        }
    }
}
