.about-section {
    padding: ac(120px, 60px) 0;
    z-index: 5;
    overflow: visible;

    /* & + .solutions {
        padding-top: ac(260px, 60px);
    }*/

    &__heading {
        margin: 0 0 44px 103px;
        max-width: 603px;
    }

    &__img {
        @mixin aspect-ratio 471, 460;
        max-width: 471px;
        width: 100%;
        flex: 1;
    }

    &__content {
        max-width: 603px;
        flex: 1;
        margin-bottom: 58px;
    }

    .content-element {
        margin: -14px 0 38px;
    }

    @mixin tab-md {
        padding-bottom: 60px;

        &__heading {
            margin: 0 0 30px 0;
        }

        &__img {
            margin-bottom: 0;
        }

        &__wrap {
            flex-direction: column;
            align-items: flex-start;
            gap: 44px;
        }

        &__content {
            margin-bottom: 0;
            max-width: 100%;
        }
    }
}
