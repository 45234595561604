@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400&family=Rubik:wght@400;500;600;700&display=swap");
:root {
    --black: #020202;
    --green: #32fdba;
    --white: #fff;

    --grey: #e9e9e9;
    --grey-2: #c2c2c2;
    --grey-3: #666666;
    --dark-grey: #3f3f3f;

    --pad-main: ac(130px, 57px, 1024);
    --pad-md: ac(120px, 57px, 1024);

    --font-main: "Rubik", sans-serif;
    --font-sec: "Poppins", sans-serif;

    /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
    --mobile-menu-start-point: 1024;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 1200px);

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 1201px);
