/* ----------------------- Common desktop header ----------------------- */
.header {
    position: absolute;
    transition: all ease 0.3s;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    height: ac(138px, 75px);
    display: flex;
    align-items: center;

    &.scrolled {
        background: transparent;
        backdrop-filter: blur(10px);
        height: ac(100px, 60px);

        .logo {
            width: ac(130px, 60px);
        }
    }

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px
    }

    .logo {
        height: 100%;
        width: ac(191px, 144px);
        flex-shrink: 0;
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.06);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .burger {
        display: none;
    }

    .menu {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 40px;
    }

    .menu-item {
        margin-right: 20px;
        position: relative;

        &.dropdown {
            & > .menu-link {
                display: inline-flex;
                justify-content: space-between;
                align-items: center;

                &::after {
                    content: "\e999";
                    font-family: "icomoon";
                    font-size: ac(10px, 8px);
                    padding-left: 10px;
                }
            }
        }
    }

    .menu-link {
        font-size: ac(16px, 14px);
    }

    .socials-links {
        display: flex;
        align-items: center;
        gap: 12px;

        @mixin media 1380 {
            display: none;
        }
    }

    .social-link {
        font-size: 24px;
    }
}

/* end of Common desktop header */

/* ----------------------- ONLY Mobile HEADER ----------------------- */
.mobile-header {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: var(--black);
    color: var(--green);
    display: none;

    min-width: 320px;
    width: 100%;
    max-width: 100%;

    opacity: 0;
    visibility: hidden;

    padding: 235px 0 20px;

    z-index: 100;
    transition: 0.3s ease-in-out;

    .cont {
        margin-right: auto;
        height: 100%;
        overflow-y: auto;
        padding-right: 20px;

        .simplebar-content {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .simplebar-scrollbar:before {
            background-color: var(--green);
            width: 5px;
        }
    }

    &__bg {
        position: absolute;
        top: -1%;
        left: 0;
        z-index: -1;
        pointer-events: none;

        @mixin mob-lg {
            left: -107%;
        }
    }

    &__bottom {
        display: flex;
        align-items: flex-end;
        padding-top: 30px;
        border-top: 1px solid var(--white);
        justify-content: space-between;
        color: var(--white);
        margin-top: auto;
    }

    &__contacts {
        max-width: 265px;
        display: grid;
        gap: 18px;
    }

    &.active {
        opacity: 1;
        visibility: visible;

        &:before {
            height: 100%;
        }

        .mobile-menu__item {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .mobile-menu {
        display: grid;
        gap: 14px;
    }

    .mobile-navbar {
        margin-bottom: 20px;
    }

    .mobile-menu__item {
        transition: 0.4s ease-in-out;
        opacity: 0;
        transform: translateY(50px);

        &:first-child {
            transition-delay: 100ms;
        }

        &:nth-child(2) {
            transition-delay: 200ms;
        }

        &:nth-child(3) {
            transition-delay: 300ms;
        }

        &:nth-child(4) {
            transition-delay: 400ms;
        }

        &:nth-child(5) {
            transition-delay: 500ms;
        }
    }

    .mobile-menu__link {
        font-size: 28px;
        transition: color 0.3s ease;
        color: var(--white);

        &:hover {
            color: var(--green);
        }
    }

    @mixin max-height 768 {
        padding-top: 180px;
    }

    @mixin max-height 625 {
        padding-top: 120px;
    }
}

/* end of ONLY Mobile HEADER */

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
    position: relative;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    display: flex;
    width: 50px;
    height: 50px;
    transition: transform 0.4s;
    margin-right: -7px;

    svg {
        width: 100%;
        height: 100%;

        path {
            fill: none;
            transition: stroke-dasharray 0.4s, stroke-dashoffset 0.4s;
            stroke: var(--green);
            stroke-width: 4;
            stroke-linecap: round;

            &.top {
                stroke-dasharray: 40 121;
            }

            &.bottom {
                stroke-dasharray: 40 121;
            }
        }
    }

    &.active {
        transform: rotate(45deg);

        svg path {
            &.top {
                stroke-dashoffset: -68px;
            }

            &.bottom {
                stroke-dashoffset: -68px;
            }
        }
    }

    @mixin min-media 1025 {
        &:hover {
            transform: scale(1.1);
        }
    }
}

.header-close-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 77;
    background: rgba(0, 0, 0, 0.7);
    pointer-events: none;
    visibility: hidden;
    opacity: 0;

    @mixin transition-all;

    &.active {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
        cursor: pointer;
    }
}

/* end of HEADER COMPONENTS */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@media (--mobile-menu-start-point) {
    .header {
        .burger,
        .menu-toggle {
            display: flex;
        }

        &__navbar {
            display: none;
        }
    }

    .mobile-header {
        display: block;
    }
}

/* end of Common desktop header Mobile HEADER */
