.our-leadership-section {
  padding-top: ac(60px, 40px);
  padding-bottom: ac(120px, 60px);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
  }

  &__decor {
    width: 100vw;
    height: ac(398px, 200px);
    bottom: ac(-70px, -30px);
    left: 15px;
    transform: translateX(-100%);
    position: absolute;
    background: var(--green);
    z-index: 10;
  }

  &__title-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: ac(50px, 30px);
  }

  &__title {
    max-width: ac(705px, 600px);

    @mixin media 651 {
      max-width: 100%;
    }
  }

  &__image {
    @mixin aspect-ratio 602, 451;
    width: 49.34%;
  }

  &__content {
    width: 43.20%;
    padding-top: ac(11px, 0px);
  }

  &__text {
    p, li {
      line-height: 168.5%;
    }
  }

  @mixin media 901 {
    &__title-content {
      order: 1;
    }
    &__image {
      width: 100%;
      order: 3;
      max-width: 600px;
    }

    &__content {

      width: 100%;
      order: 2;
      margin-bottom: 28px;
    }
  }
}