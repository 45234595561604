.gallery-section {
    overflow: hidden;

    .cont {
        max-width: 100%;
        width: 100%;
    }

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 38px;
    }

    .gallery-block {
        position: relative;
        width: 100%;
        height: ac(315px, 200px);

        .gallery-item {
            width: ac(489px, 320px);
            /*@mixin aspect-ratio 489, 315;
            max-width: 489px;
            width: 100%;*/
            margin-right: 38px;
        }

        .js-marquee {
            display: flex;
            height: 100%;
            /*width: 100%;*/
            flex-shrink: 0;
            margin-right: 0 !important;

            &-wrapper {
                height: 100% !important;
                display: flex;
            }
        }
    }

    & + .sectors-section {
        padding-top: ac(115px, 60px);
    }
}
