.blogs-section {
    display: flex;
    align-items: flex-end;
    min-height: ac(600px, 555px);
    margin-top: ac(115px, 60px);

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 20px;
    }

    .blogs-swiper {
        max-width: 603px;
        overflow: visible;
        position: static;
        margin: 0;

        &__heading {
            position: relative;
            z-index: 5;
            padding: ac(40px, 27px) ac(40px, 30px) ac(40px, 20px) 0;
            height: 100%;
            min-height: 221px;

            display: flex;
            flex-direction: column;
        }

        &__img {
            position: absolute;
            bottom: 0;
            left: 0;
            width: vw(1330);
            height: 600px;
            transform: translateX(80%);
            transition: transform 0.6s ease;
        }

        .swiper-slide {
            overflow: visible;
            height: auto;

            &:before {
                content: "";
                position: absolute;
                width: 100vw;
                height: 100%;
                background: var(--green);
                pointer-events: none;
                top: 0;
                right: 0;
                z-index: 5;
                transition: right 0.5s linear;
            }

            &-active {
                .blogs-swiper__img {
                    transform: translateX(0);
                }
            }
        }

        &__title {
            margin-bottom: ac(24px, 38px);
            max-width: 480px;
        }

        .btn {
            display: flex;
            width: fit-content;
            margin-left: auto;
            margin-top: auto;
        }
    }

    &__preview {
        flex-shrink: 0;
        max-width: 32.5%;
        width: 100%;
        overflow: hidden;
    }

    .swiper-navigation {
        position: relative;
        z-index: 5;
        margin-bottom: 30px;
        justify-content: flex-end;
    }

    .blogs-preview-swiper {
        max-width: 191px;
        margin: 0 0 40px;
        overflow: visible;

        .swiper-slide {
            height: auto;
            filter: saturate(0.1);
            min-height: 125px;
        }
    }

    @mixin media 1150 {
        .blogs-preview-swiper {
            max-width: 191px;
        }

        .blogs-swiper {
            max-width: 500px;
        }
    }

    @mixin tab-md {
        .blogs-swiper__img {
            width: 100vw;
        }
    }

    @mixin tab-sm {
        .blogs-preview-swiper {
            height: 0;
            visibility: hidden;
            margin: 0;
        }

        .swiper-navigation {
            margin-bottom: 0;
        }

        .blogs-swiper {
            max-width: 100%;

            .btn {
                margin-left: 0;
            }

            .swiper-slide-active {
                .blogs-swiper__img {
                    transform: translateX(-50%);
                }
            }
        }

        .blogs-swiper__img {
            height: 368px;
            bottom: 220px;
            width: 110vw;
            left: 50%;
            transform: translateX(-50%);
        }

        .blogs-swiper__heading:before {
            width: 120vw;
            transform: translateX(20vw);
        }

        &__wrap {
            flex-direction: column-reverse;
            gap: 20px;
        }
    }
}

.blogs {
    &__wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 30px
    }

    &__filters {
        flex: 1;
        width: 100%;
        max-width: 226px;
    }

    &__content {
        flex: 1;
        width: 100%;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
        grid-auto-rows: 1fr;
    }

    &__pagination {
        margin-top: 55px;
    }

    @mixin media 1220 {
        &__list {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @mixin media 901 {
        &__list {
            grid-template-columns: 1fr;
            grid-auto-rows: auto;
            max-width: 400px;
        }
    }

    @mixin mob-xl {
        &__wrap {
            flex-direction: column;
            justify-content: flex-start;
        }


        &__filters {
            max-width: 100%
        }
    }
}
