.statistics {
    padding: var(--pad-md) 0 ac(60px, 0px);
    overflow: visible;

    &__heading {
        max-width: 458px;
    }

    &__title {
        margin-bottom: 30px;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        max-width: 683px;
    }

    &__item {
        height: ac(264px, 215px, 901);
        text-align: center;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;

        &:nth-child(4n + 1),
        &:nth-child(4n + 2) {
            border-bottom: 1px solid var(--grey-2);
        }

        &:nth-child(odd) {
            width: ac(370px, 260px, 901);
            border-right: 1px solid var(--grey-2);
        }

        &:nth-child(even) {
            width: ac(317px, 260px, 901);
        }
    }

    &__num {
        font-size: 44px;
        font-weight: 600;
        margin-bottom: 6px;

        display: flex;
        align-items: center;
        justify-content: center;

        span {
            color: var(--green);
        }
    }

    &__img {
        @mixin aspect-ratio 500, 348;
        max-width: 500px;
        width: 100%;
        margin-top: 30px;
    }

    &__desc {
        font-size: 17px;
    }

    @mixin media 1025 {
        &__wrap {
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 0;
        }

        &__heading {
            transform: translateY(0);
            max-width: 560px;
        }

        &__img {
            max-width: 100%;
        }

        &__list {
            max-width: 100%;
        }

        &__content {
            margin-bottom: 40px;
        }

        &__item {
            width: 100% !important;
            padding: 20px;
        }
    }

    @mixin media 480 {
        &__list {
            grid-template-columns: 1fr;
        }

        &__item {
            border: none !important;

            &:not(:last-child) {
                border-bottom: 1px solid var(--grey-2) !important;
            }
        }
    }
}
