.blog-card {
    position: relative;
    background: var(--white);
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
    color: var(--black);
    transition: 0.3s ease !important;

    display: flex;
    flex-direction: column;
    height: auto;

    &__img {
        flex-shrink: 0;
        @mixin aspect-ratio 394, 220;

        img {
            transition: 0.3s ease;
        }
    }

    &__info {
        /*padding: 28px 34px;*/
        padding: 24px 28px;

        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__time {
        background: var(--grey);
        font-size: 16px;
        padding: 18px 17px 19px 28px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
        font-family: var(--font-sec);
        transform: translateZ(0);
    }

    &__date {
        font-size: 16px;
    }

    &__title {
        font-size: ac(24px, 22px);
        line-height: 144%;
        margin: 12px 0;
    }

    &__excerpt {
        margin-bottom: 24px;
        line-height: 148%;

        @mixin max-line-length 5;
    }

    .btn {
        margin-top: auto;
        width: fit-content;
    }

    &:hover {
        img {
            transform: scale(1.05);
        }

        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }
}
